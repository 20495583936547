<template>
  <div class="pt--140 pb--50" id="drones">
    <div class="">
      <v-container>
        <v-row class="row--35">
          <v-col md="12" cols="12">
            <div class="text-center section-title mb_sm--0">
              <span class="subtitle2">{{ $t('headers-top-text.drones')  }}</span>
              <h2 class="heading-title">{{ $t('headers.drones')  }}</h2>
              <p class="description">{{ $t('headers-bottom-text.drones')  }}</p>
            </div>
          </v-col>
        </v-row>
        <v-row class="row--35">
          <v-col md="12" cols="12">
            <div class="pb--60 pt--60" v-html="$t('drones.video')"></div>
            <div v-html="$t('drones.videoText')"></div>
          </v-col>
        </v-row>
        <v-row class="row--35">
          <v-col md="12" cols="12">
            <div class="pt--40">
              <div class="row mt--30 d-agency-tab">
                <v-col lg="12">
                  <div class="tab-default">
                    <v-tabs v-model="tab" flat hide-slider>
                      <v-tab v-for="(category,i) in $t('drones.questions')" :key="i" :ripple="false">
                        {{category.title}}
                      </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tab">
                      <v-tab-item v-for="(category,i) in $t('drones.questions')" :key="i">
                          <div v-html="category.content" class="pt--40" style="max-width: 800px; margin:0px auto; text-align: center; font-size: 18px; display: flex; overflow: hidden; flex-wrap: wrap; justify-content: center; align-items: center;"></div>
                      </v-tab-item>
                    </v-tabs-items>
                  </div>
                </v-col>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>
<script>
import feather from "feather-icons";
import Accordion from "@/components/accordion/Accordion.vue";
import {getLandingPageSettings} from "@/scripts/settings";
export default {
  components: {Accordion},
  data() {
    return {
      tab: null,
    };
  },
  mounted() {
    this.fetchSettings();
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
    fetchSettings(){
      getLandingPageSettings(this.$route.params.garden).then((settings) => {
      });
    },
  },
};
</script>