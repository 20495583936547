<template>
  <div class="service-area creative-service-wrapper pt--0 pb--160">
    <v-container>
      <v-row>
      <v-col lg="12" class="mission row pt--90 hidden-md-and-down" >
        <div class="col-md-6 col-lg-6 col-xl-6 col-6">
          <div class="mb--30"><h2 v-html="$t('mission.header')" class="text-left mb--30" /></div>
          <div v-html="$t('mission.lead')" class="text-justify" />
          <!--<div v-html="$t('mission.restOfText')" class="text-justify" />-->
        </div>
        <div class="col-md-6 col-lg-6 col-xl-6 col-6 text-center">
          <img :src="$t('mission.img')" />
        </div>
      </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import {getLandingPageSettings} from "@/scripts/settings";

export default {
  data() {
    return {
    };
  },
  created() {
    this.fetchSettings();
  },
  methods: {
    fetchSettings(){
      getLandingPageSettings(this.$route.params.garden).then((settings) => {
      });
    },
    
  },watch: {
    '$i18n.messages': { //gdy tylko zmieni sie cokolwiek w tłumaczeniu to uruchom poniższa funkcje
      handler: function() {
        this.setInfoIfOpen();
      },
      deep: true,
      immediate: true
    }
  }
};

</script>
<style>

</style>