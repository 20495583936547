<template>
  <div class="rn-pricing-table-area pb--160 " id="pricing" v-if="seasonalTicketsPresale">
    <v-container>
      <div class="mt_dec--30">
        <v-row>
          <v-col lg="12">
            <div class="text-center section-title mb--30 mb_sm--0">
              <span class="subtitle2">{{ $t('headers-top-text.pricing')  }}</span>
              <h2 class="heading-title">{{ $t('headers.pricing')  }}</h2>
              <p class="description">{{ $t('headers-bottom-text.pricing')  }}</p>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col
              lg="6"
              md="6"
              sm="12"
              cols="12"
              class="mt--30"
              key="pricing1">
            <div class="rn-pricing pricing1">
              <div class="pricing-table-inner">
                <div class="pricing-header">
                  <h4 class="heading-title" v-if="singleTicketsPresaleActive">{{ $t('pricing.1.presaleTitle')  }}</h4>
                  <h4 class="heading-title" v-else>{{ $t('pricing.1.title')  }}</h4>
                  <div class="pricing color-white">
                    <span class="subtitle color-white">{{ $t('pricing.1.subtitle')  }}</span>
                    <span class="price color-white" v-if="singleTicketsPresaleActive"><span><s>{{ $t('pricing.1.price')  }}</s></span><span class="discounted-price">{{ $t('pricing.1.presalePrice') }}</span></span>
                    <span class="price color-white" v-else>{{ $t('pricing.1.price')  }}</span>
                  </div>
                </div>
                <div class="pricing-body ">
                  <p class="color-white" v-if="singleTicketsPresaleActive">{{ $t('pricing.1.presaleDesc')  }}</p>
                  <p class="color-white" v-else>{{ $t('pricing.1.description')  }}</p>
                </div>
                <div class="text-center">
                  <a class="own-btn own-btn-yellow" target="_blank" v-if="activateBuyTicketButtons" :href="$t('ctaUrls.buySingleTicket')">{{ $t('pricing.1.cta')  }}</a>
                </div>
                <div class="text-center mt--30 full-pricing-link">
                  <a @click="sendEventToParent($t('pricing.1.modalHeader'),$t('pricing.1.modalText'))" class="text-underline">{{ $t('buttons.seeAllPrices')  }} ></a>
                </div>
              </div>
            </div>
          </v-col>
          <v-col
              lg="6"
              md="6"
              sm="12"
              cols="12"
              class="mt--30"
              key="pricing2">
            <div class="rn-pricing pricing2">
              <div class="pricing-table-inner" :style="blackWeekStyle">
                <div class="pricing-header">
                  <h4 class="heading-title" v-if="seasonalTicketsPresaleActive">{{ $t('pricing.2.presaleTitle')  }}</h4>
                  <h4 class="heading-title" v-else>{{ $t('pricing.2.title')  }}</h4>
                  <div class="pricing color-white">
                    <span class="subtitle color-white">{{ $t('pricing.2.subtitle')  }}</span>
                    <span class="price color-white" v-if="seasonalTicketsPresaleActive"><span><s>{{ $t('pricing.2.price')  }}</s></span><span class="discounted-price">{{ $t('pricing.2.presalePrice') }}</span></span>
                    <span class="price color-white" v-else>{{ $t('pricing.2.price')  }}</span>
                  </div>
                </div>
                <div class="pricing-body ">
                  <p class="color-white" v-if="seasonalTicketsPresaleActive">{{ $t('pricing.2.presaleDesc')  }}</p>
                  <p class="color-white" v-else>{{ $t('pricing.2.description')  }}</p>
                </div>
                <div class="text-center">
                  <a class="own-btn own-btn-turquoise" target="_blank" v-if="activateBuySeasonTicketButtons" :href="$t('ctaUrls.buySeasonaleTicket')">{{ $t('pricing.2.cta')  }}</a>
                </div>
                <div class="text-center mt--30 full-pricing-link">
                  <a @click="sendEventToParent($t('pricing.2.modalHeader'),$t('pricing.2.modalText'))" class="text-underline">{{ $t('buttons.seeAllPrices')  }} ></a>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>
<script>
import feather from "feather-icons";
import {getLandingPageSettings} from "@/scripts/settings";

export default {
  data() {
    return {
      icon: "check",
      blackWeek: false,
      blackWeekStyle: null,
      singleTicketsPresale: null,
      seasonalTicketsPresale: null,
      singleTicketsPresaleActive: false,
      seasonalTicketsPresaleActive: false,
      activateBuyTicketButtons: false,
      activateBuySeasonTicketButtons: false
    };
  },
  mounted() {
    this.fetchSettings();
  },
  methods: {
    fetchSettings() {
      getLandingPageSettings(this.$route.params.garden).then((settings) => {
        this.singleTicketsPresale = settings.singleTicketsPresale;
        this.seasonalTicketsPresale = settings.seasonalTicketsPresale;
        this.activateBuyTicketButtons = settings.activateBuyTicketButtons;
        this.activateBuySeasonTicketButtons = settings.activateBuySeasonTicketButtons;
        this.setPresale();
        this.showBlackWeek(settings);
      });
    },
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
    showBlackWeek(settings){
      if(settings.blackweek.active==true){
        var check = new Date();
        var from = new Date(settings.blackweek.periodStart);
        var to   = new Date(settings.blackweek.periodEnd);

        if(check >= from && check <= to)
        {
          if(this.$route.params.lang=='pl')
            this.blackWeekStyle = 'background-image: url("/images/bw/bw_hanger_en.png"); background-size: 180px; background-position: top right;';
          else
            this.blackWeekStyle = 'background-image: url("/images/bw/bw_hanger_en.png"); background-size: 180px; background-position: top right;';

          this.blackWeek = true;
        }
      }
    },
    setPresale(){
      if(this.singleTicketsPresale.active==true){
        var currentDate = new Date().toJSON().slice(0,10) + 'T00:00:00+0200';
        var check = new Date(currentDate);
        var from = new Date(this.seasonalTicketsPresale.periodStart);
        var to   = new Date(this.seasonalTicketsPresale.periodEnd);
        if(check >= from && check <= to){
          this.singleTicketsPresaleActive = true;
        }
      }
      if(this.seasonalTicketsPresale.active==true){
        var currentDate = new Date().toJSON().slice(0,10) + 'T00:00:00+0200';
        var check = new Date(currentDate);
        var from = new Date(this.seasonalTicketsPresale.periodStart);
        var to   = new Date(this.seasonalTicketsPresale.periodEnd);
        if(check >= from && check <= to){
          this.seasonalTicketsPresaleActive = true;
        }
      }
    },
    sendEventToParent(modalHeader,modalText){
      this.$emit("openModal", modalHeader, modalText);
    }
  }
};
</script>